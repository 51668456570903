<template>
  <section>
    <h1 class="events-title">Upcoming events</h1>
    <EventsGrid :short="false" :centered="false" :past="false" /> 
  </section>
  <section class="middle">
    <h1 class="events-title">Past events</h1>
    <EventsGrid :short="false" :centered="false" :past="true" /> 
  </section>
</template>

<script>
import EventsGrid from '@/components/EventsGrid.vue'

export default {
  name: 'Home',
  components: {
    EventsGrid
  }
}
</script>


<style lang="scss" scoped>
.events-grid {
  margin-top: 2rem;
}
</style>