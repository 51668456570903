<template>
  <div :class="['events-list', { centered : this.centered }, `size-${this.eventsToPrint.length}`]"
    v-if="eventsToPrint.length >= 1">
    <div :class="['event-list-item', event.tag == 'GameJam' ? 'tag-game-jam' : '', event.tag == 'GameNight' ? 'tag-game-night' : '']" v-for="event in eventsToPrint" :key="event.id">
      <div class="event-list-item-top">
        <div>
          <h3>{{ event.eventName }}</h3>
          <p>{{ event.eventDescription }}</p>
        </div>
        <div>
          <a v-if="event.link && subOpen(event)" class="button round" :href="event.link" target="_blank">Sign up</a>
          <div v-else class="button round disabled">
            <span v-if="event.link && !subOpen(event)">Sign up closed</span>
            <span v-else>No sign-up required</span>
          </div>
        </div>
      </div>

      <div class="event-list-item-bottom">
        <div class="meta-item">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ event.location }}</span>
        </div>
        <div>
          <div class="meta-item">
            <i class="far fa-calendar"></i>
            <span>{{ event.eventStartDate.toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
              }}</span>
          </div>
          <div class="meta-item">
            <i class="far fa-clock"></i>
            <span>{{ leadZero(event.eventStartDate.getHours()) }}:{{ leadZero(event.eventStartDate.getMinutes()) }} - {{
              leadZero(event.eventEndDate.getHours()) }}:{{ leadZero(event.eventEndDate.getMinutes()) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p v-if="!this.past" :class="['events-note', { centered : this.centered}]">There are currently no upcoming events
      planned.</p>
    <p v-else :class="['events-note', { centered : this.centered}]">There are no past events.</p>
  </div>
</template>

<script>
import { getEventsList } from "../firebase";

export default {
  name: 'EventsGrid',
  props: {
    short: Boolean,
    centered: Boolean,
    past: Boolean
  },
  data: () => {
    return {
      events: [],
    }
  },
  computed: {
    // Get upcoming events, ordered from nearest to now to furthest from now
    upcomingEvents() {
      const upcomingEvents = this.events.filter(e => e.eventEndDate > new Date()).sort((a, b) => a.eventStartDate - b.eventStartDate)
      return this.short ? upcomingEvents.slice(0, 3) : upcomingEvents
    },
    // Get past events, ordered from shortest ago from now to longest ago from now
    pastEvents() {
      let pastEvents = this.events.filter(e => e.eventEndDate < new Date()).sort((a, b) => b.eventStartDate - a.eventStartDate)
      return this.short ? pastEvents.slice(0, 3) : pastEvents
    },
    getShortMonth(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      return monthNames[date.getMonth()]
    },
    eventsToPrint() {
      return this.past ? this.pastEvents : this.upcomingEvents
    },
  },
  methods: {
    leadZero(n) {
      return n < 10 ? `0${n}` : `${n}`
    },
    getDaysBetween(d1, d2) {
      let dayOne = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
      let dayTwo = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())

      let days = (d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24)
      return Math.floor(days)
    },
    eventOngoing(e) {
      const now = new Date()
      return e.eventStartDate < now && e.eventEndDate > now
    },
    eventWeekly(e) {
      return e.tag && e.tag === "weekly"
    },
    onDataChange(items) {
      let events = []

      items.forEach((item, index) => {
        events.push({
          id: index,
          eventName: item.name,
          eventDescription: item.description,
          eventStartDate: item.startDate.toDate(),
          eventEndDate: item.endDate.toDate(),
          location: item.location,
          tag: item.tag ? item.tag : undefined,
          link: item.link ? item.link : undefined,
        })
      })

      this.events = events
    },
    subOpen(event) {
      // Subscription is open if the current date is before the start date of the event
      const now = new Date()
      return now < event.eventStartDate
    },
  },
  mounted() {
    getEventsList(list => {
      this.onDataChange(list)
    })
  },
}
</script>

<style lang="scss">

.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 2rem;

  &.centered {
    justify-content: center;

    &.size-3 { grid-template-columns: repeat(3, minmax(250px, 300px)); }
    &.size-2 { grid-template-columns: repeat(2, minmax(250px, 300px)); }
    &.size-1 { grid-template-columns: repeat(1, minmax(250px, 300px)); }
  }

  .event {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 1.75rem;
    aspect-ratio: 1;
    background-color: var(--color-bg-light);
    border-radius: 6px;

    .event-tag {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -40%);
      padding: 8px 2.5rem;
      font-size: 14px;
      background-color: var(--color-purple);
      border-radius: 100px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .event-top {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .event-date {
      display: inline-block;
      text-align: center;
      font-size: 1em;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.15em;

      .num { font-size: 2em; letter-spacing: 0; }
    }

    .event-data {
      strong {
        font-size: 1.5em;
      }

      a.link {
        vertical-align: text-top;
        margin-left: 9px;
        color: #888;
        transition: color 200ms ease;

        &:hover {
          color: #ccc;
        }
      }
      
      .metadata {
        display: grid;
        grid-template-columns: 32px auto;
        margin-top: 1rem;
        text-align: middle;
        row-gap: 4px;

        .icon {
          text-align: center;
          margin-right: 6px;
        }
      }
    }
  }
}

.events-note {
  color: var(--color-bg-light);
  font-style: italic;
  margin-top: 1rem;

  &.centered {
    text-align: center;
  }
}

// (Possibly temporary) solution for responsive, centered grid designs
@media screen and (max-width: 1200px) {
  .events-grid.centered.size-3 {
    grid-template-columns: repeat(2, minmax(250px, 350px));
  }
}

@media screen and (max-width: 800px) {
  .events-grid.centered.size-3,
  .events-grid.centered.size-2 {
    grid-template-columns: repeat(1, minmax(250px, 350px));
  }
}




.event-list-item {
  margin: 2rem 0;
  padding: 2rem 3rem;
  font-size: 16px;
  background-color: var(--color-bg-light);
  border-radius: 6px;
  border-left: 8px solid var(--color-purple-light); // #F39855

  &.tag-game-jam {
    border-color: #F39855;
  }

  &.tag-game-night {
    border-color: #3BD16F;
  }

  h3 {
    font-size: 1.5em;
    margin-bottom: 0.75em;
  }

  p {
    color: #8D8CA2;
  }

  .button {
    white-space: nowrap;
  }

  &-top,
  &-bottom {
    display: flex;
    justify-content: space-between;
  }

  &-top p {
    line-height: 1.4;
  }

  &-bottom {
    margin-top: 1.25rem;
    font-size: 16px;
    color: #8D8CA2;

    i {
      margin-right: 10px;
    }

    .meta-item {
      display: inline-block;
    }

    .meta-item+.meta-item {
      margin-left: 1.25rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .event-list-item {
    padding: 1.5rem 2rem;

    .event-list-item-top {
      display: block;

      .button {
        margin-top: 1rem;
      }
    }

    .event-list-item-bottom {
      display: block;

      .meta-item {
        display: block;
        margin-bottom: 6px;
        line-height: 1.3;
      }

      .meta-item+.meta-item {
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>